module.exports = {
  ONEPLAY_PARTNER_ID: 'e7fb1f1e-8929-11ed-90bc-02205a62d5b0',
  BASE_URL: 'https://api.cms.dev.oneream.com/api/v2/',
  API_V1_URL: 'https://rendermix.dev.oneream.com/v1/',
  GAME_ASSETS: 'https://dev-oplay-pub.in-maa-1.linodeobjects.com/game_assets/',
  FEED_ASSETS: 'https://dev-oplay-pub.in-maa-1.linodeobjects.com/feed_assets/',
  AFFILIATE_PAYOUT_PAYMENT_ASSETS: 'https://dev-oplay-pub.in-maa-1.linodeobjects.com/payouts/payment/',
  AFFILIATE_PORTAL_BASE_URL: 'https://api.affiliate.qa.oneream.com/',
  PARTNER_PACKGE_TYPES: {
    //Super Admin
    'All': {
      'topup': ['Trial'],
      'base': ['Foundation', 'Enhanced', 'Ultimate'],
      'weekly': ['Foundation', 'Enhanced', 'Ultimate'],
      'quarterly': ['Foundation', 'Enhanced', 'Ultimate'],
      'half_yearly': ['Foundation', 'Enhanced', 'Ultimate'],
      'base_nightly': ['Midnight PRO', 'Midnight Master'],
    },
    //oneplay_partner_id
    'e7fb1f1e-8929-11ed-90bc-02205a62d5b0': {
      'topup': ['Trial', 'Hourly'],
      'base': ['Foundation', 'Enhanced', 'Ultimate', 'Special Offer', 'OneSpace'],
      'quarterly': ['Foundation', 'Enhanced', 'Ultimate', 'Special Offer', 'OneSpace'],
      'half_yearly': ['Foundation', 'Enhanced', 'Ultimate', 'Special Offer', 'OneSpace'],
      'base_nightly': ['Midnight PRO', 'Midnight Master'],
    },
    //jordan_partner_id
    '3eaa80d0-dbfb-48d2-abbf-d2b51a31250f': {
      'topup': ['Trial'],
      'base': ['OnePlay Basic', 'OnePlay 4K'],
      'weekly': ['Foundation', 'Enhanced', 'Ultimate'],
      'base_nightly': ['Midnight PRO', 'Midnight Master'],
    },
  },
};
