import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Logout from '@mui/icons-material/Logout';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from 'react';

import brandLogo from '../../assets/imgs/brandLogo.svg';
import { BASE_URL } from '../../config/constants';
import Axios from '../../utils/Axios';
import { get } from '../../utils/SecureLocalStorage';

const stateData = {
  data: [],
  serverError: {
    error: false,
    message: null,
  },
};

async function logoutUser() {
  return fetch(BASE_URL + 'logout', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + get('token'),
    },
  }).then(data => data.json());
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// const StyledBadge = styled(Badge)(() => ({
//   '& .MuiBadge-badge': {
//     right: -2,
//     top: 5,
//     border: '2px solid #FFFFFF',
//     padding: '0 4px',
//   },
// }));

function stringAvatar(name) {
  return {
    sx: {
      background: 'linear-gradient(270deg, #A83AFE 0%, #6E59FE 100%);',
      color: '#ffffff',
      fontSize: '16px',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export default function AppBarPartial(props) {
  const [authUserDropdownMenuEL, setAuthUserDropdownMenuEL] = useState(null);
  const authUserDropdownMenuStatus = Boolean(authUserDropdownMenuEL);
  let auth_user = get('user');

  const showAuthUserDropdownMenu = event => {
    setAuthUserDropdownMenuEL(event.currentTarget);
  };
  const hideAuthUserDropdownMenu = () => {
    setAuthUserDropdownMenuEL(null);
  };

  const handleLogout = async event => {
    setAuthUserDropdownMenuEL(null);
    event.preventDefault();

    const response = await logoutUser();
    if (response.success) {
      localStorage.clear();
      window.location.href = '/';
    }
  };

  let selected_partner = localStorage.getItem('selected_partner') || 'All';
  const [dropdownData, setDropdownData] = React.useState(selected_partner);

  const selectDropdown = event => {
    const selected_partner = state.data.filter(
      data => data.id === event.target.value
    );
    localStorage.setItem('selected_partner', event.target.value);
    localStorage.setItem(
      'selected_partner_name',
      selected_partner.length === 1 ? selected_partner[0].name : 'All'
    );
    setDropdownData(event.target.value);
    window.location.reload();
  };

  let [state, setState] = useState(stateData);

  let getAllData = () => {
    Axios.get('partner', {
      params: { paginate: 'No' },
    }).then(response => {
      if (response.success) {
        setState(prevStyle => ({
          ...prevStyle,
          data: response.data.partners,
        }));
      } else {
        setState(prevStyle => ({
          ...prevStyle,
          serverError: {
            error: true,
            message: response.errors,
          },
        }));
      }
    });
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <AppBar position="fixed" elevation={0} className="blackBg">
      <Toolbar>
        {/* Start - Left Drawer Toggle Button */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawer}
          edge="start"
          sx={{
            border: '1px dashed #FFFFFF',
            padding: '2px',
          }}
        >
          {props.leftSidebarStatus ? <ChevronLeftIcon /> : <ChevronRight />}
        </IconButton>
        <Box ml={2}>
          <img width="120px" src={brandLogo} alt="" />
        </Box>
        {/* End - Left Drawer Toggle Button */}

        <Box sx={{ flexGrow: 1 }} />

        {/* Select Dropdown start */}
        <FormControl size="small" sx={{ width: '200px' }}>
          <Select
            value={dropdownData}
            disabled={auth_user.user_type === 'partner' ? true : false}
            onChange={selectDropdown}
            displayEmpty
            className="whiteBg"
            name="selected_partner"
          >
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {state.data &&
              state.data.map(list => {
                return (
                  <MenuItem key={list.name} value={list.id}>
                    {list.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {/* Select Dropdown End */}

        {/* Start - Notification */}
        {/* <IconButton color="inherit">
          <StyledBadge badgeContent={4}>
            <NotificationsIcon />
          </StyledBadge>
        </IconButton> */}
        {/* End - Notification */}

        {/* Start - Auth User Icon With Dropdown Menu */}
        <IconButton
          onClick={showAuthUserDropdownMenu}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={
            authUserDropdownMenuStatus ? 'account-menu' : undefined
          }
          aria-haspopup="true"
          aria-expanded={authUserDropdownMenuStatus ? 'true' : undefined}
        >
          <Avatar
            {...stringAvatar(auth_user.first_name + ' ' + auth_user.last_name)}
          />
        </IconButton>
        <Menu
          anchorEl={authUserDropdownMenuEL}
          id="account-menu"
          open={authUserDropdownMenuStatus}
          onClose={hideAuthUserDropdownMenu}
          onClick={hideAuthUserDropdownMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {/* <MenuItem onClick={hideAuthUserDropdownMenu}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={hideAuthUserDropdownMenu}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={hideAuthUserDropdownMenu}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        {/* End - Auth User Icon With Dropdown Menu */}
      </Toolbar>
    </AppBar>
  );
}
